import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginService } from "../services/authService";

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			let response = await loginService({ username, password });
			localStorage.setItem("authToken", response.token);
			navigate("/dashboard");
		} catch (error) {
			console.error("Login failed", error);
		}
	};

	return (
		<Container maxWidth="sm">
			<Box sx={{ mt: 8, p: 3, boxShadow: 3, borderRadius: 2 }}>
				<Typography variant="h4" component="h1" gutterBottom>
					Login
				</Typography>
				<form onSubmit={handleLogin}>
					<TextField
						label="Username"
						variant="outlined"
						fullWidth
						margin="normal"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<TextField
						label="Password"
						type="password"
						variant="outlined"
						fullWidth
						margin="normal"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{ mt: 2 }}>
						Login
					</Button>
				</form>
			</Box>
		</Container>
	);
};

export default Login;

import axios from "axios";

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);
export const API_URL = isLocalhost
	? "http://127.0.0.1:5000"
	: "https://stock-ai-backend.onrender.com";

export const apiClient = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

export const isAdmin = false;

export const loginService = async (credentials) => {
	const response = await apiClient.post("/login", credentials);
	return response.data;
};

export const registerService = async (userData) => {
	const response = await apiClient.post("/register", userData);
	return response.data;
};

// Helper function to get the token (e.g., from local storage)
export const getAccessToken = () => {
	return localStorage.getItem("authToken"); // Adjust according to your token storage strategy
};

import * as React from "react";
import Base from "./components/base";
import "./theme.css";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
} from "react-router-dom";
import { Container, Button, Typography, Box } from "@mui/material";
import Login from "./components/Login";
import Registration from "./components/Registration";
import PrivateRoute from "./components/PrivateRoute";
import MenuBarComp from "./components/MenuBarComp";

const HomePage = () => {
	return (
		<Container maxWidth="sm" sx={{ mt: 8, textAlign: "center" }}>
			<Typography variant="h4" gutterBottom>
				Welcome to MyStoxk
			</Typography>
			<Typography variant="body1" paragraph>
				Let AI build strategies and insights for you.
			</Typography>
			<Box sx={{ mt: 4 }}>
				<Button
					variant="contained"
					color="primary"
					sx={{ mr: 2 }}
					href="/login">
					Login
				</Button>
				<Button variant="contained" color="secondary" href="/register">
					Register
				</Button>
			</Box>
		</Container>
	);
};

// Component to conditionally render MenuBar based on the route
const Layout = ({ children }) => {
	const location = useLocation();
	const shouldHideMenuBar = ["/login", "/register", "/"].includes(
		location.pathname
	);

	return (
		<div>
			{!shouldHideMenuBar && <MenuBarComp />}{" "}
			{/* Conditionally render MenuBar */}
			{children}
		</div>
	);
};

export default function App() {
	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={
						<Layout>
							<HomePage />
						</Layout>
					}
				/>
				<Route
					path="/login"
					element={
						<Layout>
							<Login />
						</Layout>
					}
				/>
				<Route
					path="/register"
					element={
						<Layout>
							<Registration />
						</Layout>
					}
				/>

				{/* Protected Routes */}
				<Route
					path="/dashboard"
					element={
						<PrivateRoute>
							<Layout>
								<Base />
							</Layout>
						</PrivateRoute>
					}
				/>

				{/* Redirect to home if route not found */}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</Router>
	);
}

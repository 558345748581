import React, { useRef, useState, useEffect } from "react";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid2,
	Typography,
	Divider,
	Link,
	Stack,
	Button,
} from "@mui/material";
import { Select, MenuItem, FormControl } from "@mui/material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import StockChart from "./chart";
import { TICKER_LIST } from "./TickerList";
import MarkdownViewer from "./MarkdownViewer";
import { getAccessToken, isAdmin, API_URL } from "../services/authService";
import axios from "axios";
import html2canvas from "html2canvas";

const token = getAccessToken(); // Retrieve the access token

const StyledCard = styled(Card)(({ theme }) => ({
	height: "100%",
	"& .MuiCardHeader-root": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
}));

const Base = () => {
	const pageRef = useRef();

	const [selectedTicker, setSelectedTicker] = useState("");
	const [apiResponse, setApiResponse] = useState(null);

	const [basicInfo, setBasicInfo] = useState(null);
	const [marketData, setMarketData] = useState(null);
	const [chartData, setChartData] = useState(null);

	const [peerGroups, setPeerGroups] = useState(null);
	const [corporateActions, setCorporateActions] = useState(null);
	const [latestAnnouncements, setLatestAnnouncements] = useState(null);
	const [shareholdingData, setShareHoldingData] = useState(null);

	const [tableData, setTableData] = useState(null);
	const [reportsUrls, setReportsUrls] = useState(null);

	const handleTickerChange = (event) => {
		setSelectedTicker(event.target.value);
	};

	const updateStateData = (apiResponse) => {
		if (!apiResponse) return;

		const extractCompanyAndMarketData = (basicInfo) => {
			// Extracting company information
			const {
				ISIN_NUMBER,
				Industry: industry,
				fld_cin: CIN,
				lISTING_DATE: listingDate,
				sEmail,
				sURL,
			} = basicInfo;

			// Extracting market data from market_depth and price
			const {
				market_depth: {
					Fifty2WkHigh_adj: fiftyTwoWeekHigh,
					Fifty2WkLow_adj: fiftyTwoWeekLow,
					MonthHighLow: monthHighLow,
					WeekHighLow: weekHighLow,
				},
				price: { LTP: lastPrice, Chg: change, PcChg: pChange },
			} = basicInfo;

			// Define the structured output
			return {
				basicInfo: {
					isin: ISIN_NUMBER,
					industry,
					CIN,
					listingDate: listingDate.split("T")[0],
					email: sEmail,
					website: sURL,
				},
				marketData: {
					lastPrice,
					change,
					pChange,
					priceRanges: {
						fiftyTwoWeekHigh,
						fiftyTwoWeekLow,
						monthHighLow,
						weekHighLow,
					},
				},
			};
		};

		const data = extractCompanyAndMarketData(apiResponse?.basic_info);
		setBasicInfo(data.basicInfo);
		setMarketData(data.marketData);
		setTableData(apiResponse?.table_data);
		setReportsUrls(apiResponse?.reports_urls);
	};

	const handleCaptureAndSend = async () => {
		if (!pageRef.current) return;

		try {
			// Capture the page content using html2canvas
			const canvas = await html2canvas(pageRef.current, {
				width: window.innerWidth,
				height: window.innerHeight,
			});

			// Convert the canvas to a Blob (image) to send as a file
			canvas.toBlob(async (blob) => {
				const formData = new FormData();
				formData.append("screenshot", blob, "screenshot.png");
				formData.append("ticker", selectedTicker); // Add ticker to the form data

				// Send the image and form data to the API with Authorization header
				await axios.post(`${API_URL}/add_ai_summary`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				});

				console.log("Success");
			});
		} catch (error) {
			console.error("Error capturing or sending screenshot:", error);
		}
	};

	useEffect(() => {
		if (selectedTicker) {
			setApiResponse(null);
			const fetchStockInfo = async () => {
				try {
					try {
						// Fetch basic stock info
						const basicResponse = await fetch(
							`${API_URL}/show_basic_stock_info/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);

						if (!basicResponse.ok) {
							throw new Error("Failed to fetch basic stock info");
						}
						const basicData = await basicResponse.json();

						// Fetch results table stock info
						const resultsTableResponse = await fetch(
							`${API_URL}/show_results_table_stock_info/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!resultsTableResponse.ok) {
							throw new Error("Failed to fetch results table stock info");
						}
						const resultsTableData = await resultsTableResponse.json();

						// Fetch reports URLs stock info
						const reportsResponse = await fetch(
							`${API_URL}/show_reports_urls_stock_info/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!reportsResponse.ok) {
							throw new Error("Failed to fetch reports URLs stock info");
						}
						const reportsData = await reportsResponse.json();

						// Fetch latest announcements
						const announcementsResponse = await fetch(
							`${API_URL}/show_latest_annoucments/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!announcementsResponse.ok) {
							throw new Error("Failed to fetch latest announcements");
						}
						const announcementsData = await announcementsResponse.json();

						// Fetch corporate actions
						const corpActionsResponse = await fetch(
							`${API_URL}/show_corp_actions/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!corpActionsResponse.ok) {
							throw new Error("Failed to fetch corporate actions");
						}
						const corpActionsData = await corpActionsResponse.json();

						// Fetch shareholding pattern
						const shareholdingResponse = await fetch(
							`${API_URL}/show_shareholding_pattern/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!shareholdingResponse.ok) {
							throw new Error("Failed to fetch shareholding pattern");
						}
						const shareholdingData = await shareholdingResponse.json();

						// Fetch peer group information
						const peerGroupResponse = await fetch(
							`${API_URL}/show_peer_group/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);
						if (!peerGroupResponse.ok) {
							throw new Error("Failed to fetch peer group information");
						}
						const peerGroupData = await peerGroupResponse.json();

						// Fetch chart info
						const chartInfoResponse = await fetch(
							`${API_URL}/show_chart_info/${selectedTicker}`,
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${token}`,
								},
							}
						);

						if (!chartInfoResponse.ok) {
							throw new Error("Failed to fetch basic stock info");
						}
						const chartData = await chartInfoResponse.json();

						// Merge all data into a single object
						const finalData = {
							...basicData,
							...resultsTableData,
							...reportsData,
						};

						setCorporateActions(corpActionsData?.crop_actions);
						setPeerGroups(peerGroupData?.peer_group);
						setLatestAnnouncements(announcementsData?.latest_annoucments);
						setShareHoldingData(shareholdingData?.shareholding_pattern);
						setChartData(chartData?.chart_info);

						// Set API response state
						setApiResponse(finalData);
						updateStateData(finalData);
					} catch (error) {
						console.error("Error fetching stock information:", error);
					}
				} catch (error) {
					console.error("There was a problem with the fetch operation:", error);
				}
			};

			fetchStockInfo();
		}
	}, [selectedTicker]); // Run effect when selectedTicker changes

	return (
		<>
			<Box sx={{ flexGrow: 1, p: 3, mb: 10 }}>
				{selectedTicker ? (
					<>
						{apiResponse ? (
							<Grid2 container spacing={3}>
								<Box sx={{ mt: 3 }}>
									<FormControl variant="outlined" size="small">
										<Select
											value={selectedTicker}
											onChange={handleTickerChange}
											displayEmpty
											sx={{ color: "white", backgroundColor: "primary.main" }}>
											<MenuItem value="" disabled>
												Select a Ticker
											</MenuItem>
											{TICKER_LIST.map((ticker) => (
												<MenuItem key={ticker} value={ticker}>
													{ticker}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>

								<Box sx={{ mt: 3 }}>
									{isAdmin && (
										<Button
											onClick={handleCaptureAndSend}
											style={{ marginTop: "20px" }}>
											Refresh Summary
										</Button>
									)}
								</Box>

								{/* AI Card */}
								<Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
									<StyledCard>
										<CardHeader
											title="AI Overview"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<>
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														gap: 2,
														mb: 2,
													}}>
													<MarkdownViewer ticker={selectedTicker} />
												</Box>
											</>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Basic Info Card */}
								<Grid2 size={{ xs: 12, sm: 6, md: 6 }} ref={pageRef}>
									<StyledCard>
										<CardHeader
											title="Company Overview"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: 2,
												}}>
												{basicInfo &&
													Object.entries(basicInfo).map(([key, value]) => (
														<Box
															key={key}
															sx={{
																display: "flex",
																justifyContent: "space-between",
															}}>
															<Typography
																variant="subtitle1"
																sx={{ fontWeight: "medium" }}>
																{key
																	.replace(/([A-Z])/g, " $1")
																	.replace(/^./, (str) => str.toUpperCase())}
																:
															</Typography>
															<Typography>{value}</Typography>
														</Box>
													))}
											</Box>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Market Data Card */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Market Overview"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											{marketData && (
												<>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															gap: 2,
															mb: 2,
														}}>
														<Typography variant="h4">
															₹{marketData.lastPrice}
														</Typography>
														<Typography
															variant="h6"
															color={
																marketData.pChange > 0
																	? "success.main"
																	: "error.main"
															}>
															{marketData.pChange > 0 ? "+" : ""}
															{marketData.pChange}%
														</Typography>
													</Box>
													<Divider sx={{ my: 2 }} />
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															gap: 2,
														}}>
														<Box
															sx={{
																display: "flex",
																justifyContent: "space-between",
															}}>
															<Typography
																variant="subtitle1"
																sx={{ fontWeight: "medium" }}>
																52 Week High/Low:
															</Typography>
															<Typography>
																{marketData.priceRanges.fiftyTwoWeekHigh} /{" "}
																{marketData.priceRanges.fiftyTwoWeekLow}
															</Typography>
														</Box>
														<Box
															sx={{
																display: "flex",
																justifyContent: "space-between",
															}}>
															<Typography
																variant="subtitle1"
																sx={{ fontWeight: "medium" }}>
																Monthly High/Low:
															</Typography>
															<Typography>
																{marketData.priceRanges.monthHighLow}
															</Typography>
														</Box>
														<Box
															sx={{
																display: "flex",
																justifyContent: "space-between",
															}}>
															<Typography
																variant="subtitle1"
																sx={{ fontWeight: "medium" }}>
																Weekly High/Low:
															</Typography>
															<Typography>
																{marketData.priceRanges.weekHighLow}
															</Typography>
														</Box>
													</Box>
												</>
											)}
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Stock Price vs DMA Chart */}
								<Grid2 item size={{ xs: 12, sm: 12, md: 12 }}>
									<StyledCard>
										<CardHeader
											title="Stock Price vs DMA "
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<Box sx={{ width: "100%" }}>
												{chartData && <StockChart data={chartData} />}
											</Box>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Annual Income Table Data Card */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Annual Income Statement (in Cr.)"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<div
												dangerouslySetInnerHTML={{
													__html: `<table>${tableData?.AnninCr}</table>`,
												}}
												className="custom-table"
											/>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Quarterly Income Table Data Card */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Quarterly Income Statement (in Cr.)"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<div
												dangerouslySetInnerHTML={{
													__html: `<table>${tableData?.QtlyinCr}</table>`,
												}}
												className="custom-table"
											/>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Peer Groups */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Peer Groups"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<TableContainer sx={{ maxHeight: 600 }}>
												<Table stickyHeader aria-label="peer groups table">
													{/* Table Header */}
													<TableHead>
														<TableRow>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	Company Name
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	LTP (₹)
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	PE Ratio
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	EPS (₹)
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	Revenue (₹)
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	PAT (₹)
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	52 Week High (₹)
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="subtitle2"
																	fontWeight="bold">
																	52 Week Low (₹)
																</Typography>
															</TableCell>
														</TableRow>
													</TableHead>
													{/* Table Body */}
													<TableBody>
														{peerGroups &&
															peerGroups.map((peer, index) => (
																<TableRow key={index}>
																	<TableCell>{peer?.Name ?? "N/A"}</TableCell>
																	<TableCell>
																		₹
																		{peer?.LTP !== undefined &&
																		peer?.LTP !== null
																			? peer.LTP.toFixed(2)
																			: "N/A"}
																	</TableCell>
																	<TableCell>{peer?.PE ?? "N/A"}</TableCell>
																	<TableCell>₹{peer?.EPS ?? "N/A"}</TableCell>
																	<TableCell>
																		₹
																		{peer?.Revenue !== undefined &&
																		peer?.Revenue !== null
																			? peer.Revenue.toLocaleString()
																			: "N/A"}
																	</TableCell>
																	<TableCell>
																		₹
																		{peer?.PAT !== undefined &&
																		peer?.PAT !== null
																			? peer.PAT.toLocaleString()
																			: "N/A"}
																	</TableCell>
																	<TableCell>
																		₹
																		{peer?.w52hi !== undefined &&
																		peer?.w52hi !== null
																			? peer.w52hi.toFixed(2)
																			: "N/A"}{" "}
																		<br />
																		<Typography variant="caption">
																			on{" "}
																			{peer?.w52hidt
																				? new Date(
																						peer.w52hidt
																				  ).toLocaleDateString("en-US")
																				: "N/A"}
																		</Typography>
																	</TableCell>
																	<TableCell>
																		₹
																		{peer?.w52lo !== undefined &&
																		peer?.w52lo !== null
																			? peer.w52lo.toFixed(2)
																			: "N/A"}{" "}
																		<br />
																		<Typography variant="caption">
																			on{" "}
																			{peer?.w52lodt
																				? new Date(
																						peer.w52lodt
																				  ).toLocaleDateString("en-US")
																				: "N/A"}
																		</Typography>
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Shareholding Pattern */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Shareholding Pattern"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											{/* Render HTML content directly */}
											{shareholdingData && (
												<Box
													sx={{ overflowX: "auto" }}
													dangerouslySetInnerHTML={{ __html: shareholdingData }}
												/>
											)}
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Corporate Actions */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Corporate Actions"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>
																<strong>Date</strong>
															</TableCell>
															<TableCell>
																<strong>Title</strong>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{corporateActions &&
															corporateActions.map((action, index) => (
																<TableRow key={index}>
																	<TableCell>{action?.date}</TableCell>
																	<TableCell>{action?.title}</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Reports URLs Card */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Financial Reports"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent>
											<Stack spacing={2}>
												{reportsUrls &&
													reportsUrls.map((report, index) => (
														<Box key={index}>
															<Typography
																variant="body1"
																sx={{ fontWeight: "medium" }}>
																{report.title} -{" "}
																{new Date(report.date).toLocaleDateString()}
																<Link
																	sx={{ marginLeft: 2 }}
																	href={report.url}
																	target="_blank"
																	rel="noopener noreferrer">
																	View Report
																</Link>
															</Typography>
															<Divider sx={{ my: 1 }} />
														</Box>
													))}
											</Stack>
										</CardContent>
									</StyledCard>
								</Grid2>

								{/* Latest Announcements */}
								<Grid2 item size={{ xs: 12, sm: 6, md: 6 }}>
									<StyledCard>
										<CardHeader
											title="Latest Announcements"
											titleTypographyProps={{ variant: "h6" }}
										/>
										<CardContent sx={{ maxHeight: 600, overflowX: "auto" }}>
											<div className="space-y-4">
												{latestAnnouncements &&
													latestAnnouncements.map((announcement, index) => {
														const date = new Date(
															announcement.date
														).toLocaleDateString("en-GB", {
															day: "2-digit",
															month: "short",
															year: "numeric",
														});
														return (
															<div key={index} className="space-y-2">
																<div className="flex justify-between items-center">
																	<div className="flex flex-col">
																		<Typography
																			variant="subtitle1"
																			sx={{ fontWeight: "medium" }}>
																			{date}
																		</Typography>
																		<Typography
																			variant="h6"
																			color="textPrimary">
																			{announcement.title}
																		</Typography>
																	</div>
																</div>
																<Typography
																	variant="body2"
																	color="textSecondary">
																	{announcement.sub_title}
																</Typography>
																<a
																	href={announcement.url}
																	target="_blank"
																	rel="noopener noreferrer"
																	style={{ textDecoration: "none" }}>
																	<Typography variant="body2" color="primary">
																		View PDF
																	</Typography>
																</a>
																{index < latestAnnouncements.length - 1 && (
																	<Divider sx={{ marginY: 2 }} />
																)}
															</div>
														);
													})}
											</div>
										</CardContent>
									</StyledCard>
								</Grid2>
							</Grid2>
						) : (
							<Typography variant="h4" sx={{ margin: 20 }}>
								Loading...!
							</Typography>
						)}
					</>
				) : (
					<Typography variant="h4" sx={{ margin: 20 }}>
						Welcome, select stock to get started!
						<Box sx={{ mt: 3 }}>
							<FormControl variant="outlined" size="small">
								<Select
									value={selectedTicker}
									onChange={handleTickerChange}
									displayEmpty
									sx={{ color: "white", backgroundColor: "primary.main" }}>
									<MenuItem value="" disabled>
										Select a Ticker
									</MenuItem>
									{TICKER_LIST.map((ticker) => (
										<MenuItem key={ticker} value={ticker}>
											{ticker}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Typography>
				)}
			</Box>
		</>
	);
};

export default Base;

import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuBarComp = () => {
	const navigate = useNavigate();

	const handleNavigation = (path) => {
		navigate(path);
	};

	const handleLogout = () => {
		// Remove the token from localStorage
		localStorage.removeItem("authToken");
		// Redirect to home page
		navigate("/");
	};

	return (
		<AppBar position="static" color="primary">
			<Toolbar>
				<Typography variant="h6" sx={{ flexGrow: 1 }}>
					Stoxk
				</Typography>
				<Button color="inherit" onClick={handleLogout}>
					Logout
				</Button>{" "}
				{/* Logout button added */}
			</Toolbar>
		</AppBar>
	);
};

export default MenuBarComp;

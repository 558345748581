import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
	// Check if the user is authenticated by verifying a token or session
	const token = localStorage.getItem("authToken"); // For example, using localStorage to check the token
	return token ? true : false;
};

const PrivateRoute = ({ children }) => {
	return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
